<template>
  <v-dialog
    :value="value"
    @input="close"
    width="80%"
    style="height: 800px !important"
    :scrollable="false"
  >
    <v-card>
      <ProgressCircle
        class="py-6"
        v-if="isLoading && urlPreview"
      />
      <iframe
        v-else
        :src="urlPreview"
        width="100%"
        height="700"
        frameBorder="0"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin    from "@/mixins/dialog";
import ProgressCircle from "./ProgressCircle";

export default {
  name: "PreviewDialog",
  components: { ProgressCircle },
  mixins: [dialogMixin],

  props: {
    isLoading: { type: String, default: null },
    urlPreview: { type: String, default: "" },
  },

  data() {
    return {};
  },

  methods: {

  }
};
</script>
